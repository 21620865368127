@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Uber Move Text';
  font-style: normal;
  font-weight: 400;
  src: local('Uber Move Text'),
       url('https://fonts.cdnfonts.com/s/39554/UberMoveTextRegular.woff') format('woff');
}

@font-face {
  font-family: 'Uber Move Text';
  font-style: normal;
  font-weight: 300;
  src: local('Uber Move Text'),
       url('https://fonts.cdnfonts.com/s/39554/UberMoveTextLight.woff') format('woff');
}

@font-face {
  font-family: 'Uber Move Text';
  font-style: normal;
  font-weight: 500;
  src: local('Uber Move Text'),
       url('https://fonts.cdnfonts.com/s/39554/UberMoveTextMedium.woff') format('woff');
}

@font-face {
  font-family: 'Uber Move Text';
  font-style: normal;
  font-weight: 700;
  src: local('Uber Move Text'),
       url('https://fonts.cdnfonts.com/s/39554/UberMoveTextBold.woff') format('woff');
}


p, h1, h2, h3, h4, h5, h6, a, li, ul, input {
    font-family: 'Uber Move Text', sans-serif;
}

input:focus, select, select:focus {
    outline: none;
}

.backdrop-opacity-disabled {
  top: 0;
}

/* Disable text selection */
body {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Disable pinch-to-zoom */
html, body {
  touch-action: manipulation;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer/Edge */
}

::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari) */
}